'use client';

import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { FALLBACK_LOCALE } from '../config';
import { Container } from '../src/components/container/container';
import { Footer } from '../src/components/footer/Footer';
import { Layout } from '../src/components/layout/layout';
import { RecursiveContent } from '../src/components/recursive-content/recursive-content';
import { getFooter } from '../src/services/get-footer';
import { getNotFound } from '../src/services/get-not-found';
import { getPages } from '../src/services/get-pages';
import classes from '../src/styles/_404.module.scss';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const [pages, footer, content] = await Promise.all([
    getPages(locale ?? FALLBACK_LOCALE),
    getFooter(locale ?? FALLBACK_LOCALE),
    getNotFound(locale ?? FALLBACK_LOCALE),
  ]);

  return {
    props: {
      locale: locale ?? FALLBACK_LOCALE,
      pages,
      footer,
      content,
      ...(await serverSideTranslations(locale ?? FALLBACK_LOCALE)),
    },
  };
};

export type Error404PageProps = {
  locale: string;
  pages: Awaited<ReturnType<typeof getPages>>;
  footer: Awaited<ReturnType<typeof getFooter>>;
  content: Awaited<ReturnType<typeof getNotFound>>;
};

export const Error404Page = ({ pages, footer, content }: Error404PageProps) => {
  return (
    <Layout
      className={classes.root}
      pages={pages ?? []}
      footer={
        <>
          {footer && (
            <Footer
              contactFormLink={footer.contactForm}
              companyName={footer.company}
              city={footer.city}
              companyDescription={footer.description}
              street={footer.street}
              addressAddition={footer.addressaddition}
              zip={footer.zip}
              phoneNumber={footer.phone}
              emailAddress={footer.mail}
              links={footer.links}
              legalLinks={footer.legalLinks}
              socialMediaLinks={footer.socialMediaLinks}
            />
          )}
        </>
      }
    >
      <Container fullWidth className={classes.content}>
        {content && (
          <RecursiveContent
            blockClasses={{ button: classes.button }}
            data={content.content}
            typoClassName={classes.typo}
          />
        )}
      </Container>
    </Layout>
  );
};

export default Error404Page;
